import { Component } from "react";

class Strategy1InfoBox extends Component {
  render() {
    return (
      <div className={this.props.show ? "modal is-active" : "modal"}>
        <div className="modal-background" onClick={this.props.handleClose}></div>

        <div className="modal-card">
          <header className="modal-card-head">
            <h5 className="modal-card-title">Condition Info (Strategy #1)</h5>
            <button className="delete" onClick={this.props.handleClose}></button>
          </header>

          <section className="modal-card-body">
            <p className="subtitle">Statistics info of instance - #{this.props.instance.id}</p>

            <h4 className="is-size-4 mb-2">Statistics:</h4>

            <table className="table is-striped is-hoverable is-fullwidth is-bordered">
              <tbody>
                <tr>
                  <th>Stopped:</th>
                  <td>{this.props.instance.stopped ? "Yes" : "No"}</td>
                </tr>
                <tr>
                  <th>Status:</th>
                  <td>{this.props.instance.status}</td>
                </tr>
                <tr>
                  <th>Entered Ins:</th>
                  <td>{this.props.instance.enteredIns}</td>
                </tr>
                <tr>
                  <th>Entered/Remaining Qty:</th>
                  <td>{this.props.instance.enteredQty}, {this.props.instance.remainingQty}</td>
                </tr>
                <tr>
                  <th>Entered Price:</th>
                  <td>{this.props.instance.enteredPrice}</td>
                </tr>
                <tr>
                  <th>LTP:</th>
                  <td>{this.props.instance.ltp}</td>
                </tr>
                <tr>
                  <th>Running P&L:</th>
                  <td>{this.props.instance.runningPnl}</td>
                </tr>
                <tr>
                  <th>Booked P&L:</th>
                  <td>{this.props.instance.bookedPnl}</td>
                </tr>
                <tr>
                  <th>Locked SL / Price At SL Lock:</th>
                  <td>{this.props.instance.lockedSL}, {this.props.instance.priceAtSLLock}</td>
                </tr>
              </tbody>
            </table>

            <h4 className="is-size-4 mb-3">Inputs:</h4>

            <table className="table is-striped is-hoverable is-fullwidth is-bordered">
              <tbody>
                <tr>
                  <th>Index:</th>
                  <td>{this.props.instance.index}</td>
                </tr>
                <tr>
                  <th>Start Time:</th>
                  <td>{this.props.instance.startTime}</td>
                </tr>
                <tr>
                  <th>Stop Time:</th>
                  <td>{this.props.instance.stopTime}</td>
                </tr>
                <tr>
                  <th>Lots:</th>
                  <td>{this.props.instance.lots}</td>
                </tr>
                <tr>
                  <th>Timeframe:</th>
                  <td>{this.props.instance.timeframe}</td>
                </tr>
                <tr>
                  <th>Strike Distance:</th>
                  <td>{this.props.instance.strikeDistance}</td>
                </tr>
                <tr>
                  <th>Expiry:</th>
                  <td>{this.props.instance.expiry}, {this.props.instance.expiryType}</td>
                </tr>
                <tr>
                  <th>Initial SL/First Target/Trailing SL:</th>
                  <td>{this.props.instance.initialSL}, {this.props.instance.firstTarget}, {this.props.instance.trailingSL}</td>
                </tr>
                <tr>
                  <th>Trading Mode:</th>
                  <td>{this.props.instance.tradingMode}</td>
                </tr>
                <tr>
                  <th>To Use Ichimoku:</th>
                  <td>{this.props.instance.toUseIchimoku ? "Yes": "No"}</td>
                </tr>
                <tr>
                  <th>To Use Supertrend:</th>
                  <td>{this.props.instance.toUseSupertrend ? "Yes" : "No"}</td>
                </tr>
                <tr>
                  <th>Ichimoku Conversion Line Period:</th>
                  <td>{this.props.instance.ichimokuConversionLinePeriod}</td>
                </tr>
                <tr>
                  <th>Ichimoku Base Line Period:</th>
                  <td>{this.props.instance.ichimokuBaseLinePeriod}</td>
                </tr>
                <tr>
                  <th>Ichimoku Leading Span B Period:</th>
                  <td>{this.props.instance.ichimokuLeadingSpanBPeriod}</td>
                </tr>
                <tr>
                  <th>Ichimoku Lagging Span Period:</th>
                  <td>{this.props.instance.ichimokuLaggingSpanPeriod}</td>
                </tr>
                <tr>
                  <th>Supertrend Period:</th>
                  <td>{this.props.instance.supertrendPeriod}</td>
                </tr>
                <tr>
                  <th>Supertrend Multiplier:</th>
                  <td>{this.props.instance.supertrendMultiplier}</td>
                </tr>
              </tbody>
            </table>
          </section>

          <footer className="modal-card-foot"></footer>
        </div>
      </div>
    );
  }
}

export default Strategy1InfoBox;