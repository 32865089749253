import { Component } from "react";

class Select extends Component {
  render() {
    const firstLetter = this.props.name.slice(0, 1).toUpperCase();
    const restOfString = this.props.name.slice(1);

    const displayName = firstLetter + restOfString;

    return (
      <div className="field mb-3">
        <label htmlFor={this.props.name} className="label">{displayName}:</label>
        <div className="control">
          <div className="select is-fullwidth">
            <select
              id={this.props.name}
              name={this.props.name}
              value={this.props.inputValue}
              onChange={this.props.handleEdit}
            >
              {
                this.props.options.map((option, i) => (
                  <option key={i} value={option}>{option}</option>
                ))
              }
            </select>
          </div>
        </div>
      </div>
    );
  }
}

export default Select;