import { Component } from "react";
import Input from "./inputComponent.jsx";
import Select from "./selectComponent.jsx";

class Strategy1AddDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      index: "NIFTY",
      startTime: "09:15",
      stopTime: "15:00",
      lots: 1,
      timeframe: "5minute",
      strikeDistance: 0,
      expiry: "2024-01-01",
      expiryType: "Weekly",
      initialSL: 0,
      firstTarget: 0,
      trailingSL: 0,
      tradingMode: "Paper",

      toUseIchimoku: "True",
      toUseSupertrend: "True",

      ichimokuConversionLinePeriod: 0,
      ichimokuBaseLinePeriod: 0,
      ichimokuLeadingSpanBPeriod: 0,
      ichimokuLaggingSpanPeriod: 0,

      supertrendPeriod: 0,
      supertrendMultiplier: 0
    }
  }

  componentDidMount() {
    let localStorageState = localStorage.getItem("manjunath-ichimoku-algo-inputs");

    if (localStorageState === undefined || localStorageState === null || localStorageState === "") {
      let stateToSave = JSON.stringify(this.state);
      localStorage.setItem("manjunath-ichimoku-algo-inputs", stateToSave);

    } else {
      localStorageState = JSON.parse(localStorageState);
      this.setState(localStorageState);
    }
  }

  componentDidUpdate() {
    console.log(this.state);
  }

  handleEdit = async e => {
    const name = e.target.name;
    const value = e.target.value;

    this.setState(prevState => {
      const newState = { ...prevState };
      newState[name] = value;
      return newState;
    }, () => {
      let stateToSave = JSON.stringify(this.state);
      localStorage.setItem("manjunath-ichimoku-algo-inputs", stateToSave);
    });
  }

  handleCheckboxEdit = async e => {
    const name = e.target.name;
    const checked = e.target.checked;

    console.log(checked);

    this.setState(prevState => {
      const newState = { ...prevState };
      newState[name] = checked;
      return newState;
    }, () => {
      let stateToSave = JSON.stringify(this.state);
      localStorage.setItem("manjunath-ichimoku-algo-inputs", stateToSave);
    });

    console.log(localStorage.getItem("manjunahth-ichimoku-algo-inputs"));
  }

  handleAdd = async () => {
    await this.props.addInstance(this.state, 1);
    this.props.hideAddDialog();
  }

  render() {
    return (
      <div className={this.props.open ? "modal is-active" : "modal"}>
        <div className="modal-background" onClick={this.props.hideAddDialog}></div>

        <div className="modal-card is-fullwidth">
          <header className="modal-card-head">
            <h5 className="modal-card-title">Add Instance</h5>
            <button className="delete" onClick={this.props.hideAddDialog}></button>
          </header>

          <section className="modal-card-body">
            <p className="subtitle">Add a new instance which will run its seperate strategy instance</p>

            <form onSubmit={this.handleAdd}>
              <Select
                name="index"
                handleEdit={this.handleEdit}
                inputValue={this.state.index}
                options={["NIFTY", "BANKNIFTY"]}
              />

              <Input
                name="startTime"
                inputType="time"
                handleEdit={this.handleEdit}
                inputValue={this.state.startTime}
              />

              <Input
                name="stopTime"
                inputType="time"
                handleEdit={this.handleEdit}
                inputValue={this.state.stopTime}
              />

              <Input
                name="lots"
                inputType="number"
                inputValue={this.state.lots}
                handleEdit={this.handleEdit}
              />

              <Select
                name="timeframe"
                handleEdit={this.handleEdit}
                inputValue={this.state.timeframe}
                options={["minute", "3minute", "5minute", "10minute", "15minute", "30minute", "60minute"]}
              />

              <Input
                inputType="number"
                name="strikeDistance"
                handleEdit={this.handleEdit}
                inputValue={this.state.strikeDistance}
              />

              <Input
                name="expiry"
                inputType="date"
                handleEdit={this.handleEdit}
                inputValue={this.state.expiry}
              />

              <Select
                name="expiryType"
                handleEdit={this.handleEdit}
                options={["Weekly", "Monthly"]}
                inputValue={this.state.expiryType}
              />

              <Input
                name="initialSL"
                inputType="number"
                handleEdit={this.handleEdit}
                inputValue={this.state.initialSL}
                displayName="Initial SL (in points)"
              />

              <Input
                name="firstTarget"
                inputType="number"
                handleEdit={this.handleEdit}
                inputValue={this.state.firstTarget}
                displayName="First Target (in points)"
              />

              <Input
                name="trailingSL"
                inputType="number"
                handleEdit={this.handleEdit}
                inputValue={this.state.trailingSL}
                displayName="Trailing SL (in points)"
              />

              <Select
                name="tradingMode"
                options={["Paper", "Live"]}
                handleEdit={this.handleEdit}
                inputValue={this.state.tradingMode}
              />

              <hr />

              <Select
                name="toUseIchimoku"
                options={["True", "False"]}
                handleEdit={this.handleEdit}
                inputValue={this.state.toUseIchimoku}
              />

              <Select
                name="toUseSupertrend"
                options={["True", "False"]}
                handleEdit={this.handleEdit}
                inputValue={this.state.toUseSupertrend}
              />

              <Input
                inputType="number"
                handleEdit={this.handleEdit}
                name="ichimokuConversionLinePeriod"
                displayName="Ichimoku Conversion Line Period"
                inputValue={this.state.ichimokuConversionLinePeriod}
              />

              <Input
                inputType="number"
                handleEdit={this.handleEdit}
                name="ichimokuBaseLinePeriod"
                displayName="Ichimoku Base Line Period"
                inputValue={this.state.ichimokuBaseLinePeriod}
              />

              <Input
                inputType="number"
                handleEdit={this.handleEdit}
                name="ichimokuLeadingSpanBPeriod"
                displayName="Ichimoku Leading Span B Period"
                inputValue={this.state.ichimokuLeadingSpanBPeriod}
              />

              <Input
                inputType="number"
                handleEdit={this.handleEdit}
                name="ichimokuLaggingSpanPeriod"
                displayName="Ichimoku Lagging Span Period"
                inputValue={this.state.ichimokuLaggingSpanPeriod}
              />

              <Input
                inputType="number"
                name="supertrendPeriod"
                handleEdit={this.handleEdit}
                displayName="Supertrend Period"
                inputValue={this.state.supertrendPeriod}
              />

              <Input
                inputType="number"
                name="supertrendMultiplier"
                handleEdit={this.handleEdit}
                displayName="Supertrend Multiplier"
                inputValue={this.state.supertrendMultiplier}
              />
            </form>
          </section>

          <footer className="modal-card-foot">
            <button onClick={this.handleAdd} className="button is-primary">Add</button>
            <button onClick={this.props.hideAddDialog} className="button is-danger">Cancel</button>
          </footer>
        </div>
      </div>
    );
  }
}

export default Strategy1AddDialog;