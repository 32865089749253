import { Component } from "react";

import Logs from "./logs.jsx";
import Navbar from "./navbar.jsx";
import Settings from "./settings.jsx";
import Orderbook from "./orderbook.jsx";
import Strategy1Instances from "./strategy1Instances.jsx";

class MainPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: "strategy1Instances"
    }
  }

  changePage = page => {
    console.log(`Changing to ${page}`);

    this.setState({
      currentPage: page
    });
  }

  render() {
    let page;

    if (this.state.currentPage === "strategy1Instances") {
      page = <Strategy1Instances
        getInstances={this.props.getInstances}
        instances={this.props.strategy1Instances}
        addInstance={this.props.addInstance}
        startInstance={this.props.startInstance}
        stopInstance={this.props.stopInstance}
        squareoffInstance={this.props.squareoffInstance}
        deleteInstance={this.props.deleteInstance}
      />
    } else if (this.state.currentPage === "orderbook") {
      page = <Orderbook
        orders={this.props.orders}
        getOrders={this.props.getOrders}
        clearOrders={this.props.clearOrders}
      />
    } else if (this.state.currentPage === "logs") {
      page = <Logs
        logs={this.props.logs}
        getLogs={this.props.getLogs}
        clearLogs={this.props.clearLogs}
      />
    } else if (this.state.currentPage === "settings") {
      page = <Settings
        stopStrategy={this.props.stopStrategy}
        startStrategy={this.props.startStrategy}
        strategyStatus1={this.props.strategyStatus1}
        fetchStrategyStatus={this.props.fetchStrategyStatus}
      />
    }

    return (
      <div>
        <Navbar
          username={this.props.username}
          handleLogout={this.props.handleLogout}
        />

        <div className="tabs is-toggle is-toggle-rounded is-fullwidth p-2 m-2">
          <ul>
            <li
              className={this.state.currentPage === "strategy1Instances" ? "is-active" : ""}
            >
              <a
                href="/#"
                onClick={() => this.changePage("strategy1Instances")}
                className={this.state.currentPage !== "strategy1Instances" ? "has-background-white" : ""}
              >
                Strategy #1 Instances
              </a>
            </li>

            <li
              className={this.state.currentPage === "orderbook" ? "is-active" : ""}
            >
              <a
                href="/#"
                onClick={() => this.changePage("orderbook")}
                className={this.state.currentPage !== "orderbook" ? "has-background-white" : ""}
              >
                Orderbook
              </a>
            </li>

            <li
              className={this.state.currentPage === "logs" ? "is-active" : ""}
            >
              <a
                href="/#"
                onClick={() => this.changePage("logs")}
                className={this.state.currentPage !== "logs" ? "has-background-white" : ""}
              >
                Logs
              </a>
            </li>

            <li
              className={this.state.currentPage === "settings" ? "is-active" : ""}
            >
              <a
                href="/#"
                onClick={() => this.changePage("settings")}
                className={this.state.currentPage !== "settings" ? "has-background-white" : ""}
              >
                Settings
              </a>
            </li>
          </ul>
        </div>

        {page}
      </div>
    );
  }
}

export default MainPage;